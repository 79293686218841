<template>
    <div v-if="title" class="p-relative ml-n3">
        <div class="title-container"/>
        <div class="p-absolute-center title-text">
            {{ title }}
        </div>
    </div>
</template>

<script>
export default {
    name: "TitleContainer",
    props: {
        title: {
            type: String,
            default: '',
        }
    }
}
</script>

<style scoped>


.title-container {
    background-color: var(--v-secondary-base);
    border-radius: 0 12px 12px 0;
    padding: 16px 0;
    width: 85%;
}

.title-text {
    color: #FFFFFF;
}

.p-absolute-center {
    position: absolute;
    left: calc(50% + 6px);
    top: 50%;
    transform: translate(calc(-50% - 0px), -50%);
}

</style>